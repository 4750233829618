function route(path, component, name) {
    return {
        name: component || name,
        path,
        component: resolve =>
            import (`@/views/${component}.vue`).then(resolve),
        props: true,
        beforeEnter: (to, from, next) => {
            next();
        },
        beforeRouteUpdate(to, from, next) {
            next();
        }
    };
}

function createPaths(options) {
    try {
        let paths = [];
        options.forEach(option => {
            traverse(option, paths);
        });

        return paths;
    } catch (error) {}
}

function traverse(option, paths) {
    paths = paths || [];
    if (option) {
        if (option.parentId) {
            // push the name in the sub-level array
            paths.push(JSON.parse(option.data));
        }

        if (option.children) {
            for (var index = 0; index < option.children.length; index++) {
                // for each node in children
                paths = traverse(option.children[index], paths); // travel the node, increasing the current sub-level
            }
        }
    }

    return paths;
}

function configRoutes(router) {
    const options = localStorage.getItem("options");

    if (options) {
        const paths = createPaths(JSON.parse(options));

        if (paths && paths.length > 0) {
            let routeData = router.options.routes.find(r => r.path === "/");
            if (routeData.children) routeData.children.length = 0;

            routeData.children = paths.map(path => route(path.path, path.component, path.name));

            // Always add Dashboard page as default
            routeData.children.push({
                name: "Dashboard",
                path: "/dashboard",
                component: resolve =>
                    import (`@/views/Dashboard.vue`).then(resolve)
            });

            router.addRoutes([routeData]);
        }
    }
}

export default configRoutes;