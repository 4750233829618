import { mapState, mapActions } from 'vuex';
import i18n from '../../i18n/index';
import configRoutes from '../../utils/routerUtils';

export default {
    data() {
        return {
            valid: false,
            username: '',
            password: '',
            rules: {
                required: value => !!value || i18n.t('field-required')
            },
            paths: []
        };
    },
    computed: {
        ...mapState('auth', ['status'])
    },
    methods: {
        ...mapActions('auth', ['login']),
        submit() {
            if (this.$refs.form.validate()) {
                const { username, password } = this;
                this.login({ username, password })
                    .then(user => {
                        configRoutes(this.$router);
                        this.$router.push('/');
                    }).catch(error => {
                        if (error) {
                            if (!error.auth) {
                                this.$notify({
                                    group: "app",
                                    type: "error",
                                    text: i18n.t('login.user-or-password-text-error')
                                });
                            }
                        }
                    });
            }
        }
        // loggin(username, password) {
        //     try {

        //         let options = [{
        //             id: 1,
        //             data: '{\\"name\\":\\"Autorizaciones activas\\",\\"component\\":\\"activeAuthorizations/activeAuthorizations\\",\\"path\\":\\"/active-authorizations\\",\\"icon\\":null}',
        //             parentId: null
        //         }];
        //         console.log('Metodo Users: ', username);
        //         console.log('Metodo Password: ', password);
        //         const response = await ApiService.post('/auth', { username, password })
        //         TokenService.saveToken(response.data.token)
        //         StorageService.saveUserData(response.data.user)
        //             //StorageService.saveOptions(response.data.options)
        //         StorageService.saveOptions(options)
        //         ApiService.setHeader()

        //         return response.data.token
        //     } catch (error) {
        //         throw error
        //     }
        // }
    }
};