var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-content",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "fill-height": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "align-center": "", "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm8: "", md4: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c("v-toolbar", {
                                attrs: { dark: "", color: "primary" },
                              }),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: [_vm.rules.required],
                                          "prepend-icon": "mdi-account",
                                          name: "login",
                                          label: "Usuario",
                                          type: "text",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.submit.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.username,
                                          callback: function ($$v) {
                                            _vm.username =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "username",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "password",
                                          rules: [_vm.rules.required],
                                          "prepend-icon": "mdi-lock",
                                          name: "password",
                                          label: "Contraseña",
                                          type: "password",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.submit.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        loading: _vm.status.loggingIn,
                                        color: "primary",
                                      },
                                      on: { click: _vm.submit },
                                    },
                                    [_vm._v("Ingresar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }